.btn-border {
  border-top: 2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom:4px solid black;
}

#launchOutlined{
  display: inline;
}

.chakra-modal__body{
  text-align: center;
  line-height: 2;
}