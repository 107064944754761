/* .App {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 768;
  max-height: 1024;

} */

.ButtomMenu::after {
    justify-content: center;
    text-align: center;
}

.ButtomMenu .chakra-link {
    margin: 10px;
    min-width: 50px;
    font-size: x-small;
}

.ButtomMenu .chakra-link img {
    height: 20px;
}

#top .ButtomMenu .chakra-link img {
    width: 23px;
}

#stories .ButtomMenu .chakra-link img {
    width: 23px;
}

#self_study .ButtomMenu .chakra-link img {
    width: 20px;
}

#online_lesson .ButtomMenu .chakra-link img {
    width: 22px;
}

#notices .ButtomMenu .chakra-link img {
    width: 19px;
}

#settings .ButtomMenu .chakra-link img {
    width: 29px;
}