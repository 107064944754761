.btn-border {
  border-top: 2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom:4px solid black;
}

/* checkbox */
.css-aq8gvp {
  border: 2px black;
}


.amplify-tabs {
  display: none !important;
}
@media only screen and (min-width: 280px){
  #bg-image{
    height: 653px;
    width: 260px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 250px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 275px;
    justify-content: center;
  }
  #signup-header{
    width: 275px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 20px;
    padding-right: 0px;
    margin-left: -5px;
  }
}

@media only screen and (min-width: 320px){
  #bg-image{
    height: 568px;
    width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 310px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 310px;
    justify-content: center;
  }
  #signup-header{
    width: 310px;
  }
  #signup-header-title{
    margin-left: -75px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 28px;
  }
}

@media only screen and (min-width: 360px){
  #bg-image{
    height: 740px;
    width: 360px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 350px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 340px;
    justify-content: center;
  }
  #signup-header{
    width: 340px;
  }
  #signup-header-title{
    margin-left: -75px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 60px;
  }
}

@media only screen and (min-width: 375px){
  #bg-imaget{
    height: 568px;
    width: 320px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 390px){
  #bg-image{
    height: 568px;
    width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 393px){
  #bg-image{
    height: 568px;
    width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 412px){
  #bg-image{
    height: 568px;
    width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 414px){
  #bg-image{
    max-height: 568px;
    max-width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 540px){
  #bg-image{
    max-height: 568px;
    max-width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 768px){
  #bg-image{
    max-height: 568px;
    max-width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 820px){
  #bg-image{
    max-height: 568px;
    max-width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 912px){
  #bg-image{
    max-height: 568px;
    max-width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 1024px){
  #bg-image{
    max-height: 568px;
    max-width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}

@media only screen and (min-width: 1280px){
  #bg-image{
    max-height: 568px;
    max-width: 320px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 280px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 362px;
  }
  /* signup form */
  #radix-2-content-1{
    width: 362px;
    justify-content: center;
  }
  #signup-header{
    width: 362px;
  }
  #signup-header-title{
    margin-left: -70px;
  }
  #signup_cancel{
    height: 25px;
    padding-right: 0px;
    margin-left: 75px;
  }
}